























































import {Vue, Component} from 'vue-property-decorator'
import LsEditor from '@/components/editor.vue'
import {apiProtocolInfo, apiProtocolEdit} from '@/api/setting/shop'

@Component({
    components: {
        LsEditor,
    }
})
export default class SettingProtocol extends Vue {
    /** S Data **/
    // 表单数据
    form: any = {
        service_agreement_name:	'',     // 服务协议名称
        service_agreement_content: '',  // 服务协议内容
        privacy_policy_name: '',        // 隐私政策名称
        privacy_policy_content: '',     // 隐私政策内容
    }
    /** E Data **/


    /** S Methods **/
    // 初始化表单数据
    initFormData() {
        apiProtocolInfo().then(res => {
            // 表单同步于接口响应数据
            for (const key in res) {
                if (!this.form.hasOwnProperty(key)) continue
                this.form[key] = res[key]
            }
        }).catch(() => {
            this.$message.error('数据加载失败，请刷新重载')
        })
    }

    // 重置表单数据
    onResetFrom() {
        this.initFormData()
        this.$message.info('已重置数据')
    }

    // 提交表单
    onSubmitFrom(formName: string) {
        const refs = this.$refs[formName] as HTMLFormElement

        refs.validate((valid: boolean) => {
            if (!valid) return;
            const loading = this.$loading({ text: '保存中' })

            apiProtocolEdit({
                ...this.form
            }).then(() => {
                this.$message.success('保存成功')
            }).catch(() => {
                this.$message.error('保存失败')
            }).finally(() => {
                loading.close()
            })
        })
    }
    /** E Methods **/


    /** S Life Cycle **/
    created() {
        this.initFormData()
    }
    /** E Life Cycle **/
}

